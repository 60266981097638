let webPsupport = false;

window.addEventListener('load', function () {
  if (!document.documentElement.lang.includes('en')) flatpickr.localize(document.documentElement.lang.includes('nl') ? flatpickr.l10ns.nl : flatpickr.l10ns.de);
  flatpickr.l10ns.default.firstDayOfWeek = 1;
  flatpickr('.datepicker', {
    dateFormat: "d.m.Y",
  });

  const menuItems = Array.prototype.slice.call(document.querySelectorAll('.js-nav-item'));
  menuItems.forEach(item => {
    item.addEventListener('mouseover', showMenu);
    item.addEventListener('click', showMenu);
  })
  const mainNavigation = document.getElementById('mainNavigation');
  if (mainNavigation) mainNavigation.addEventListener('mouseleave', hideMenu);

  const sidebarTrigger = document.getElementById('sidebarTrigger');
  if (sidebarTrigger) {
    sidebarTrigger.addEventListener('click', showSidebar);
    document.getElementById('mainSidebar').addEventListener('click', hideSidebar);
    document.getElementById('sidebar-close').addEventListener('click', () => {
      document.getElementById('mainSidebar').classList.remove('visible');
    });
  };

  supportsWebp().then(value => {
    webPsupport = value;
    console.log('webp support', webPsupport);
    lazyloading();
  }).catch(() => { lazyloading() });
});

function showMenu() {
  const navigation = document.getElementById('mainNavigation');

  if (this.classList.contains('has-children')) {
    if (!navigation.classList.contains('sub-active')) {
      navigation.classList.add('sub-active');
    };
    if (navigation.classList.contains('search-active')) {
      navigation.classList.remove('search-active');
    };
    const currentActive = document.querySelector('.navigation__content.active');
    if (currentActive) currentActive.classList.remove('active');
    const childrenId = this.getAttribute('data-children');
    document.getElementById(childrenId).classList.add('active');
  } else {
    navigation.classList.remove('sub-active');
  };
};

function hideMenu() {
  if (this.classList.contains('sub-active')) {
    this.classList.remove('sub-active');
  }
}

function showSidebar() {
  document.getElementById('mainSidebar').classList.add('visible');
}

function hideSidebar(event) {
  if (event.target.parentElement.nodeName === "BODY") {
    document.getElementById('mainSidebar').classList.remove('visible');
  }
}

function lazyloading() {
  let lazyImages = [].slice.call(document.querySelectorAll('img.lazy'));
  let lazyBackgrounds = [].slice.call(document.querySelectorAll('.lazy-background'));

  if ('loading' in HTMLImageElement.prototype) {
    // if support the attribute "loading"
    console.log('lazy loading mode: attribute "loading"');
    lazyImages.forEach(lazyImage => {
      setImageSrc(lazyImage);
    });

    // Intersection Observer for lazy backgrounds
    let lazyBackgroundObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          entry.target.classList.add(`${webPsupport ? 'webp' : 'no-webp'}`);
          lazyBackgroundObserver.unobserve(entry.target);
        }
      });
    });
    lazyBackgrounds.forEach(lazyBackground => {
      lazyBackgroundObserver.observe(lazyBackground);
    })
  } else {
    if ('IntersectionObserver' in window) {
      // if support Intersection Observer
      console.log('lazy loading mode: Intersection Observer');

      // lazy Images
      let lazyImageObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            let lazyImage = entry.target;
            setImageSrc(lazyImage);
            lazyImage.classList.remove('lazy');
            lazyImageObserver.unobserve(lazyImage);
          }
        });
      });
      lazyImages.forEach(lazyImage => {
        lazyImageObserver.observe(lazyImage);
      });

      // lazy backgrounds
      let lazyBackgroundObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            entry.target.classList.add(`${webPsupport ? 'webp' : 'no-webp'}`);
            lazyBackgroundObserver.unobserve(entry.target);
          }
        });
      });
      lazyBackgrounds.forEach(lazyBackground => {
        lazyBackgroundObserver.observe(lazyBackground);
      })
    }
    else {
      // fall back to the more compatible method
      console.log('lazy loading mode: simple js lazy loading');
      let active = false;

      const lazyLoad = () => {
        if (!active) {
          active = true;

          setTimeout(function () {
            lazyImages.forEach(lazyImage => {
              if ((lazyImage.getBoundingClientRect().top <= window.innerHeight && lazyImage.getBoundingClientRect().bottom >= 0)
                && getComputedStyle(lazyImage).display !== 'none') {
                setImageSrc(lazyImage);
                lazyImage.classList.remove('lazy');
                lazyImages = lazyImages.filter(image => image !== lazyImage);
              }
            });

            lazyBackgrounds.forEach(lazyBackground => {
              if ((lazyBackground.getBoundingClientRect().top <= window.innerHeight && lazyBackground.getBoundingClientRect().bottom >= 0)
                && getComputedStyle(lazyBackground).display !== 'none') {
                lazyBackground.classList.add('visible');
                lazyBackground.classList.add(`${webPsupport ? 'webp' : 'no-webp'}`);
                lazyBackgrounds = lazyBackgrounds.filter(image => image !== lazyBackground);
              }
            });

            if (lazyImages.length === 0 && lazyBackgrounds.length === 0) {
              document.removeEventListener('scroll', lazyLoad);
              window.removeEventListener('resize', lazyLoad);
              window.removeEventListener('orientationchange', lazyLoad);
            }

            active = false;
          }, 200)
        }
      }

      lazyLoad();
      document.addEventListener('scroll', lazyLoad);
      window.addEventListener('resize', lazyLoad);
      window.addEventListener('orientationchange', lazyLoad);
    }
  }
}

function supportsWebp() {
  return new Promise((resolve, reject) => {
    if (!self.createImageBitmap) resolve(false);
    const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
    fetch(webpData)
      .then((r) => {
        return r.blob();
      })
      .then((blob) => {
        return createImageBitmap(blob);
      })
      .then(() => {
        resolve(true)
      }, () => {
        resolve(false)
      })
      .catch(() => {
        resolve(false)
      })
  });
}

function getExt(filename) {
  var idx = filename.lastIndexOf('.');
  return (idx < 1) ? "" : filename.substr(idx + 1);
}

function isJpgOrPng(imgSrc) {
  const ext = getExt(imgSrc);
  return ext === 'jpg' || ext === 'jpeg' || ext === 'png';
}

function setImageSrc(image) {
  let query = `${webPsupport && isJpgOrPng(image.dataset.src) ? '?format=webp' : ''}`;
  image.src = image.dataset.src + query;
  if (image.dataset.srcset) image.srcset = image.dataset.srcset;
}

document.addEventListener('DOMContentLoaded', () => {

  const cookieWidgetToggles = document.querySelectorAll('.js-showWidget');
  cookieWidgetToggles.forEach((elem) => elem.addEventListener('click', () => {
    const parent = elem.parentElement.parentElement.parentElement;
    const overlay = parent.querySelector('.cookieWidget__overlay');
    const iFrame = parent.querySelector('iframe');

    iFrame.setAttribute('src', iFrame.getAttribute('data-src'));
    overlay.remove();

  }))

});